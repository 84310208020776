import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { domain, clientId, audience } from "../auth_config.json";
import { Auth0Plugin } from "./auth0";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { i18n } from "@/plugins/i18n";
import "@/plugins/vue-formulate.plugin";
import "./plugins/vue-select.plugin";
import FlagIcon from "vue-flag-icon";
import { library } from "@fortawesome/fontawesome-svg-core";
import "vue-search-select/dist/VueSearchSelect.css";
import {
  faUserSecret,
  faUser,
  faArrowLeft,
  faVideo,
  faComments,
  faPaperclip,
  faFilePdf,
  faMobile,
  faSignInAlt,
  faSignOutAlt,
  faHashtag,
  faTimesCircle,
  faClock,
  faHammer,
  faLock,
  faPalette,
  faArrowsAlt,
  faTimes,
  faGripVertical,
  faChevronDown,
  faTrash,
  faEdit,
  faPencilAlt,
  faPager,
  faToggleOn,
  faBell,
  faFileAlt,
  faBars,
  faHeadset,
  faUsers,
  faTags,
  faLanguage,
  faHandshake,
  faPlusSquare,
  faPaperPlane,
  faEnvelope,
  faQrcode,
  faHome,
  faCamera,
  faEllipsisV,
  faDoorOpen,
  faCalendar,
  faArrowCircleRight,
  faPlayCircle,
  faChevronRight,
  faAngleDown,
  faAngleUp,
  faChevronLeft,
  faClone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "@/plugins/vee-validate.plugin";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import VueMeta from "vue-meta";
import VueCarousel from "vue-carousel";
import ToggleButton from "vue-js-toggle-button";
import VueScrollactive from "vue-scrollactive";
import VueSidebarMenu from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";
import CountryFlag from "vue-country-flag";
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import SideBar from "./layouts/SideBar.vue";
import NoSidebar from "./layouts/NoSideBar.vue";

library.add(
  faClone,
  faUserSecret,
  faUser,
  faArrowLeft,
  faVideo,
  faComments,
  faPaperclip,
  faFilePdf,
  faMobile,
  faSignInAlt,
  faSignOutAlt,
  faHashtag,
  faTimesCircle,
  faClock,
  faHammer,
  faLock,
  faPalette,
  faArrowsAlt,
  faTimes,
  faGripVertical,
  faChevronDown,
  faTrash,
  faEdit,
  faPencilAlt,
  faPager,
  faToggleOn,
  faBell,
  faFileAlt,
  faBars,
  faHeadset,
  faUsers,
  faTags,
  faPaperclip,
  faLanguage,
  faHandshake,
  faPlusSquare,
  faPaperPlane,
  faEnvelope,
  faQrcode,
  faHome,
  faCamera,
  faEllipsisV,
  faDoorOpen,
  faCalendar,
  faArrowCircleRight,
  faPlayCircle,
  faChevronRight,
  faAngleDown,
  faAngleUp,
  faArrowsAlt,
  faChevronLeft
);

Vue.component("sidebar-layout", SideBar);
Vue.component("no-sidebar-layout", NoSidebar);

import "./assets/css/custom.scss";
import "./assets/css/tribespot.scss";

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
});

Vue.use(require("vue-moment"));
Vue.use(VueCarousel);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(FlagIcon);
Vue.use(VueMeta);
Vue.use(VueScrollactive);
Vue.use(VueSidebarMenu);
Vue.use(VueQuillEditor /* { default global options } */);
Vue.component(VueQrcode.name, VueQrcode);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("country-flag", CountryFlag);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
