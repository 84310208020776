import { Workshop } from "@/models/workshop.model";
import BaseService from "./base-service";

class WorkshopsService extends BaseService<Workshop> {
  get endpoint() {
    return "/workshops";
  }
}

export default new WorkshopsService();
